import SEO from 'common/components/seo';
import React from 'react';
import { ResetCSS } from '../common/assets/css/style';
import ErrorContainer from '../containers/ErrorContainer';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ResetCSS />
    <ErrorContainer />
  </>
);

export default NotFoundPage;
